import styled from "styled-components";


export default function MobileLogin(){

        return(
            <MobilePage>
               
                <HeaderTitle>ZeterAds</HeaderTitle>
                <MobileForm>
                    <label>UserName</label>
                    <CustomInput type="text" placeholder="Enter your phone number" />
                    <label>Password</label>
                    <CustomInput type="password" placeholder="Enter your password" />
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',width: '100%'}}>
                    <a href="#" style={{color:'lightblue'}}>Forgot Password?</a>
                    <a href="" style={{color:'lightblue'}}>Create Account</a>
                    </div>

                    <LoginButton>Login</LoginButton>
                </MobileForm>
            </MobilePage>
        );
}


const HeaderTitle = styled.h1`
    color: white;
    font-size: 24px;
    font-weight: lighter;
    margin: 10px;
    background-color: #0001;
    padding: 10px;
    border-radius: 5px;
    margin-top: 100px;
`;

const HeaderSliderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
  );
`;

const MobilePage = styled.div` 
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
        background: linear-gradient(
    141deg,
    rgba(185, 183, 198, 0) 0%,
    rgba(23, 29, 145, 0.39) 0%,
    rgb(10, 10, 13) 54%,
    rgba(15, 25, 212, 0.63) 94%
  );
`;

const CreateAccountButton = styled.button`
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    position: relative;
    right: 0;
    cursor: pointer;
`;

const CustomInput = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #000;
    border-radius: 5px;
    color: black;
`;

const LoginButton = styled.button`
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: white;
    color: #000;
    width: 100%;
    cursor: pointer;
    & :hover{
        background-color: red;
        color: white;
    }
`;

const MobileForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: lighter;
    align-self: center;
    height: 300px;
    background-color: #fff1;
`;
